<template>
  <!-- <section class="hero is-primary hero-header">
    <div class="hero-body is-paddingless"> -->
  <nav id="app-detail-header"
    class="navbar"
    :class="[`is-app-detail-header-${$user.info.theme}`]">
    <div class="navbar-brand">
      <div class="navbar-item is-hidden-desktop">
        <div class="field is-grouped">
          <p class="control">
            <a id="toggle"
              class="button tooltip is-tooltip is-tooltip-topleft is-primary"
              :class="buttonCSS"
              @click="toggleSide"
              title="Toggle Side Menu"
              data-tooltip="Toggle Side Menu">
              <span class="icon">
                <i class="mdi mdi-menu" />
              </span>
            </a>
          </p>
          <p v-if="!serviceError && showExport"
            class="control">
            <a id="export"
              class="button tooltip is-tooltip-topleft is-success"
              :class="buttonCSS"
              @click="exportreport"
              title="Export"
              data-tooltip="Export">
              <span class="icon">
                <i class="mdi mdi-file-export" />
              </span>
            </a>
          </p>
          <p v-if="!serviceError && showEmail"
            class="control">
            <a id="email"
              class="button tooltip is-tooltip-topleft is-primary"
              :class="buttonCSS"
              @click="email"
              title="Email"
              data-tooltip="Email">
              <span class="icon">
                <i class="mdi mdi-email" />
              </span>
            </a>
          </p>
          <p v-if="!serviceError && showPrint"
            class="control">
            <a id="print"
              class="button tooltip is-tooltip-topleft"
              :class="buttonCSS"
              @click="print"
              title="Print"
              data-tooltip="Print">
              <span class="icon">
                <i class="mdi mdi-printer" />
              </span>
            </a>
          </p>
        </div>
      </div>
      <div class="navbar-burger burger"
        data-target="action-button-menu">
        <span />
        <span />
        <span />
      </div>
    </div>
    <div id="detail-nav-menu"
      class="navbar-start is-hidden-touch">
      <div class="navbar-item">
        <h1 class="title">
          <a class="button is-primary is-outlined"
            v-if="showToggle"
            :class="buttonCSS"
            @click="toggleSide">
            <span class="icon">
              <i class="mdi mdi-menu mdi-24px" />
            </span>
          </a>
          <span v-for="(route, idx) in $route.matched.slice()"
            :key="idx">
            <span v-if="idx > 0">
              <span class="icon">
                <i class="mdi mdi-chevron-right" />
              </span>
            </span>
            <span>
              {{ route.meta.title }}
            </span>
          </span>
        </h1>
      </div>
    </div>
    <div class="navbar-end is-hidden-touch">
      <div class="navbar-item">
        <div class="field is-grouped">
          <p v-if="!serviceError && showExport"
            class="control">
            <a id="export"
              class="button is-success"
              :class="buttonCSS"
              @click="exportreport">
              <span class="icon">
                <i class="mdi mdi-file-export mdi-18px" />
              </span>
              <span>Export</span>
            </a>
          </p>
          <p v-if="!serviceError && showEmail"
            class="control">
            <a id="email"
              class="button is-primary"
              :class="buttonCSS"
              @click="email">
              <span class="icon">
                <i class="mdi mdi-email mdi-18px" />
              </span>
              <span>Email</span>
            </a>
          </p>
          <p v-if="!serviceError && showPrint"
            class="control">
            <a id="print"
              class="button"
              :class="buttonCSS"
              @click="print">
              <span class="icon">
                <i class="mdi mdi-printer mdi-18px" />
              </span>
              <span>Print</span>
            </a>
          </p>
          <p v-if="showBack"
            class="control">
            <a id="back"
              class="button is-primary"
              :class="buttonCSS"
              @click="back">
              <span class="icon">
                <i class="mdi mdi-arrow-left-bold mdi-18px" />
              </span>
              <span>Back</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </nav>
  <!-- </div>
  </section> -->
</template>
<script>
import HttpStatus from '@/components/http-status'
import EventBus from '@/components/EventBus'
import { mapGetters } from 'vuex'
import { EventHubTypes } from '@/enums'

export default {
  name: 'PrintPreviewAppDetailHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    showPrint: {
      type: Boolean,
      default: true
    },
    showEmail: {
      type: Boolean,
      default: true
    },
    showExport: {
      type: Boolean,
      default: true
    },
    showBack: {
      type: Boolean,
      default: true
    },
    showToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      serviceError: false,
      access: {},
      showSideMenu: true
    }
  },
  computed: {
    buttonCSS: function () {
      return 'is-inverted'
    },
    ...mapGetters(['licenceExpired'])
  },
  watch: {
    $route: function () {
      this.changeTitle()
    }
  },
  created() {
    EventBus.$on('serviceError', (data) => {
      this.handleBusEvent(data)
    })
    this.$eventHub.$on(EventHubTypes.AutoHideSideMenu, (data) => {
      this.handleSideMenuEvent(data)
    })
  },
  mounted() {
    if (this.$route && this.$route.meta && this.$route.meta.access) {
      this.access = this.$route.meta.access
    }
    this.changeTitle()
  },
  methods: {
    print() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, 'print')
    },
    email() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, 'email')
    },
    exportreport() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, 'exportreport')
    },
    back() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, 'back')
    },
    toggleSide() {
      this.showSideMenu = !this.showSideMenu
      this.$eventHub.$emit(EventHubTypes.ToggleSideMenu, this.showSideMenu)
    },
    handleBusEvent(data) {
      this.serviceError = data === HttpStatus.NOT_FOUND
    },
    handleSideMenuEvent(data) {
      this.showSideMenu = !data
    },
    changeTitle() {
      const routes = this.$route.matched.slice()
      var title = ''
      routes.forEach((r, idx) => {
        if (idx > 0) {
          title += ' '
        }
        title += r.meta.title
      })
      document.title = title // parentRoute.meta.title
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
